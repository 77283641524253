
.valentine-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgb(170, 159, 202);
}
#img {
    position: absolute;
    left: 530px;
    top: 140px;
    width:300px;
    
}
.yesButton {
    border-color: #cbc4d258;
    color: rgb(170, 159, 202);
    background-color: #FFFDD0;
    border-radius: 3px;
}


.noButton {
    border-color: #cbc4d258;
    color: rgb(170, 159, 202);
    background-color: #FFFDD0;
    border-radius: 3px;
}
